import { DefaultConfig } from './types';
import { defaultConfig } from './default';

export const defaultJuniper: DefaultConfig = {
  ...defaultConfig,
  brand: 'juniper',
  healthCoachCreation: true,
  showDeliveryInstructions: true,
  defaultTimezone: 'Australia/Sydney',
  syncConsultationsEnabled: true,
  medicareEnabled: true,
  lgaEnabled: true,
  // disabled temporarily, more info: https://linear.app/eucalyptus/issue/ENG-1777/[admins-ui]-temp-disable-shop-plugs
  plugsEnabled: false,
  shopEnabled: true,
};
